<template>
  <div class="card-wrap">
    <div class="card">
      <div class="content">{{content}}</div>
      <div class="author">———{{author}}</div>
      <div class="datetime">{{datetime}}</div>
      <div class="btns">
        <div class="like-btn">点赞数{{praise_num}}</div>
        <div class="review-btn">评论数{{comments.length}}</div>
      </div>
      <div class="comments" v-if="!!comments.length">
        <div
          class="comment-item"
          v-for="(item,index) in comments"
          :key="index"
        >{{item.username}}：&nbsp;&nbsp;{{item.comment}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: String,
    author: String,
    datetime: String,
    comments: {
      type: Array,
      default: function () {
        return [];
      },
    },
    praise_num:{
      type: Number,
      default: 0,
    }
  },
  mounted() {
    this.$emit("load");
  },
};
</script>

<style lang="scss" scoped>
.card-wrap {
  .card {
    border: 1px solid #fff;
    box-sizing: border-box;
    padding: 5px;
    .content {
      font-size: 12px;
      line-height: 20px;
      color: rgb(252, 243, 203);
      word-wrap: break-word;
      word-break: normal;
    }
    .author {
      text-align: right;
      font-size: 12px;
      line-height: 20px;
      color: rgb(252, 243, 203);
      margin-top: 12px;
    }
    .datetime {
      text-align: right;
      font-family: Tahoma,Helvetica,Arial;
      color: rgb(255, 255, 255);
      font-size: 12px;
    }
    .btns {
      .like-btn {
        padding: 0 5px;
        height: 22px;
        border: 1px solid #fff;
        line-height: 22px;
        font-size: 14px;
        display: inline-block;
        color: #fff;
      }
      .review-btn {
        padding: 0 5px;
        height: 22px;
        border: 1px solid #fff;
        line-height: 22px;
        font-size: 14px;
        display: inline-block;
        color: #fff;
        margin-left: 5px;
      }
    }
    .comments {
      padding: 5px 0;
      .comment-item {
        color: rgb(255, 255, 255);
        font-size: 12px;
        margin: 5px 0;
        word-wrap: break-word;
        word-break: normal;
      }
    }
  }
}
</style>