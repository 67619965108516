<template>
  <div class="page">
    <div class="cloud">
      <img src="@/assets/images/cloud.png" />
    </div>
    <div
      ref="content"
      class="main-content"
      v-infinite-scroll="getDataList"
      infinite-scroll-disabled="disabled"
    >
      <Waterfall
        :list="dataList"
        :gutter="10"
        :width="240"
        :breakpoints="{500: {rowPerView: 1}}"
        animationEffect="fadeInUp"
        backgroundColor="transparent"
        ref="waterfall"
      >
        <template slot="item" slot-scope="props">
          <message-card
            :content="props.data.content"
            :author="props.data.leave_name"
            :datetime="props.data.created_at"
            :comments="props.data.comment_list"
            :praise_num="props.data.praise_num"
            :comment_num="props.data.comment_num"
            @load="$refs.waterfall.refresh()"
          ></message-card>
        </template>
      </Waterfall>
    </div>
    <div class="footer">
      <div class="other-href" @click="toNextPage('/message-all')">返回所有留言</div>
      <br />
      <div class="other-href" @click="toNextPage('/main')">返回首页</div>
    </div>
  </div>
</template>

<script>
import Waterfall from "vue-waterfall-plugin";
import MessageCard from "./message-card";
import api from "@/api";
export default {
  data() {
    return {
      dataList: [],
      scrollTop: 0,
      loading: false,
      pageParams: {
        page: 1,
        pageSize: 20,
      },
    };
  },
  created() {
    this.getDataList();
  },
  mounted() {
    this.$refs.content.addEventListener("scroll", (e) => {
      this.scrollTop = e.target.scrollTop;
    });
  },
  activated() {
    this.$refs.content.scrollTop = this.scrollTop;
  },
  computed: {
    disabled() {
      return this.loading;
    },
  },
  methods: {
    getDataList(e) {
      this.loading = true;
      api.getMine(this.pageParams).then((res) => {
        this.pageParams.page++;
        let arr = res.list;
        this.dataList = [...this.dataList, ...arr];
        this.loading = arr.length == 0;
      });
    },
    toNextPage(url) {
      this.$router.push(url);
    },
  },
  components: {
    Waterfall,
    MessageCard,
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  background-color: #127987;
  .cloud {
    width: 100%;
    height: 246px;
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -123px;
    animation: 14s linear 0s infinite normal both running
      tempKeyframesForMotion0;
    img {
      height: 100%;
    }
  }
  .main-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-image: linear-gradient(
      rgba(18, 121, 135, 0),
      rgba(18, 121, 135, 1)
    );
    padding: 20px 0;
    text-align: center;
    .other-href {
      font-size: 14px;
      color: rgb(255, 255, 255);
      text-decoration-line: underline;
      text-align: center;
      display: inline-block;
      margin: 0 auto;
      padding: 8px 16px;
      & + .other-href {
        margin-top: 10px;
      }
    }
  }
}
</style>